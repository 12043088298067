.main_nav img {
    width: 25px;
    margin-right: 2%;
}
.footer {
    bottom: 0;
    height: 10vh;
    width: 100%;
}
.footer_inner {
    margin-left: 13%;
    margin-top: 1%;
}
.footer_inner p {
    font-weight: 500;
}
.footer_inner a {
    text-decoration: none;
}