.cards_wrapper {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.cards {
    width: 20%;
    text-align: center!important;
    margin: 0 2%;

}
.cards img {
    width: 100px;
    margin-bottom: 2%;
}
.cards h5 {
    font-weight: bold;
    margin-bottom: 6%;
}

@media screen and (max-width: 500px), screen and (max-device-width: 500px) {
    .cards_wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 20%;
    }
    .cards {
        margin: 0 2% auto;
        width: 100%;
    }
}