.image_banner_wrapper {
    margin-top: 5%;
    text-align: center;
}
.image_banner_wrapper img {
    width: 5.5%;
    margin-right: 2%;
}

@media screen and (max-device-width:640px), screen and (max-width:640px) {
    .image_banner_wrapper {
        display: none;
    }
}