.step_one_form, .step_two_form {
    margin: 0 20% auto;
    border: 0!important;
}
.form_row_1, .form_row_2, .form_row_3 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2%;
}
.step_one_form label, .step_two_form label {
    font-weight: bolder;
}
.step_one_form input, .step_two_form input {
    height: 60px;
    border: 0;
    border-radius: 5px;
    border-bottom: 1px solid rgb(94, 212, 252);
    font-weight: 500;
}
.form_item_small {
    width: 10%;
}
.form_item_small_others {
    width: 40%;
}
.form_item {
    width: 30%;
}
.form_item_wide {
    width: 63%;
}
.form_item_wide_2 {
    width: 45%;
}
.form_button_group {
    margin-top: 3%;
    text-align: center;
}
.form_button_group button {
    padding: 10px;
    height: 60px;
    width: 160px;
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid orange;
    font-weight: bold;
    transition-duration: .5s;
    margin: 0 2%;
}
.form_button_group button:hover {
    background-color: orange;
    transition-duration: .5s;
}


@media screen and (max-device-width:640px), screen and (max-width:640px) {
    .step_one_form, .step_two_form {
        margin: 2% 1% auto;
    }
    .form_row_1, .form_row_2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 5%;
    }
    .step_one_form label, .step_two_form label {
        font-weight: normal;
    }
    .step_one_form input, .step_two_form input {
        height: auto;
        font-weight: normal;
        margin-bottom: 5%;
    }
    .form_item_small {
        width: 48%;
    }
    .form_item_small_others {
        width: 48%;
    }
    .form_item {
        width: 48%;
    }
    .form_item_wide {
        width: 48%;
    }
    .form_item_wide_2 {
        width: 48%;
    }

}