.top_wrapper {
    height: 35vh;
    text-align: center;
    background-color: rgb(94, 212, 252);
}
.top_wrapper {
    padding-top: 2%;
}
.top_wrapper h3 {
    margin-bottom: 2%;
}
.top_wrapper img {
    width: 20%;
    margin-right: 5%;
}
.top_wrapper button {
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid orange;
    transition-duration: .5s;
    font-weight: bold;
}
.top_wrapper button:hover {
    background-color: orange;
    transition-duration: .5s;
}


@media screen and (max-width: 500px), screen and (max-device-width: 500px) {
    .top_wrapper h3 {
        font-size: 0px;
    }
    .top_wrapper img {
        width: 80%;
        margin-bottom: 10%;
    }
}

@media screen and (max-width: 800px), screen and (max-device-width: 800px) {
    .top_wrapper h3 {
        font-size: 0px;
    }
    .top_wrapper img {
        width: 60%;
    }
}